
































































































































import { Component, Vue } from 'vue-property-decorator';
import OnceButton from '@/components/OnceButton.vue';
import * as api from '@/gen/api';
import label from '@/resources/labels/p0108label.json';
import Message from '@/common/message';

@Component({
  components: {
    'once-button': OnceButton
  }
})
export default class P0108 extends Vue {
  private buttonLoading = false;
  private hasError = false;
  private newEmail = '';
  private newEmailConfirm = '';
  private readonly label: any = label;

  private isNoticeEmailCarrier: boolean = false;

  public async submitChangeEmail() {
    this.hasError = false;
    const result = await this.$validator.validateAll();
    if (!result) {
      return;
    }
    if (this.newEmail == this.$auth.user.email) {
      this.hasError = true;
      return;
    }
    this.buttonLoading = true;

    const config = await this.$getConfigWithToken(this.$auth);
    const curUserApi = new api.CurrentUserApi(config);
    const res = await curUserApi
      .updateCurrentUserEmail({
        email: this.newEmail
      })
      .catch(this.$tokenErrHandler)
      .catch(() => {
        // when email has been used
        this.hasError = true;
        this.buttonLoading = false;
      });
    if (res === undefined) {
      // error handled
      return;
    }

    // success
    const returnTo = `${window.location.origin}/update-email-ok`;
    this.$auth.logout({
      returnTo: returnTo,
      opAppLogout: true,
      opAppLogoutMessage: Message.get('2000512')
    });
  }

  private checkCarrierEmail() {
    const carrierDomains = ['@docomo.ne.jp', '@ezweb.ne.jp'];
    this.isNoticeEmailCarrier = carrierDomains.some(domain =>
      this.newEmail.endsWith(domain)
    );
  }

  /**
   * 戻るボタン押下
   */
  private back(): void {
    this.$router.push('/account-info');
  }
}
